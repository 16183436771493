export const ActivityStatus = require('./ActivityStatus');

export const MINIMUM_PASSWORD_SIZE = 6;
export const ZOOM_TO_AREA = 18;
export const MESSAGE_AUTO_CLOSE_TIME = 5 * 1000;
export const GET_LAYER_DATA_TIMEOUT = 10 * 1000;
export const MESSAGE_EXTENDED_AUTO_CLOSE_TIME = 10 * 1000;
export const IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/jp2',
];
export const DEFAULT_LOCALE = 'pt';
export const RESUME = 'res';
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const ELEMENT_FIELDS_SIZE = 4;

export const PERMISSIONS = {
  EDIT: 'edit',
  CREATE: 'create',
  DELETE: 'delete',
  CONFIG: 'config',
  GRANT: 'grant',
  STATE: 'state',
};

export const FILTER_TYPE = {
  CHECKBOX: 'CHECKBOX',
  INPUT: 'INPUT',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
};

export const SHAPE_TYPE = {
  DISTANCES: 'distances',
  POLYGONS: 'polygons',
  RECTANGLES: 'rectangles',
  CIRCLES: 'circles',
};

export const ACTIVITY_ACTIONS = {
  ADD_USER: 'add_user',
  CONCLUDE_ACTIVITY: 'conclude_activity',
  REOPEN_ACTIVITY: 'reopen_activty',
  DELETE_ACTIVITY: 'delete_activty',
  EXPORT: 'export',
};

export const SHAPE_OVERLAY = {
  MARKER: 'MARKER',
  POLYGON: 'POLYGON',
  RECTANGLE: 'RECTANGLE',
  CIRCLE: 'CIRCLE',
};

export const SHAPE_TYPE_TO_ICON = {
  [SHAPE_TYPE.DISTANCES]: 'settings_ethernet',
  [SHAPE_TYPE.POLYGONS]: 'format_shapes',
  [SHAPE_TYPE.RECTANGLES]: 'crop_3_2',
  [SHAPE_TYPE.CIRCLES]: 'radio_button_unchecked',
};

export const SHAPE_TYPE_TO_DRAWER = {
  [SHAPE_TYPE.DISTANCES]: SHAPE_OVERLAY.MARKER,
  [SHAPE_TYPE.POLYGONS]: SHAPE_OVERLAY.POLYGON,
  [SHAPE_TYPE.RECTANGLES]: SHAPE_OVERLAY.RECTANGLE,
  [SHAPE_TYPE.CIRCLES]: SHAPE_OVERLAY.CIRCLE,
};

export const AREA_DEFAULT_OPTIONS = {
  name: 'Tipo Padrão',
  strokeColor: '#ff00ff',
  fillColor: '#d3d3d3',
  strokeWeight: 3,
  fillOpacity: 0.55,
  zIndex: Number.MAX_SAFE_INTEGER,
};

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};

export const LAYER_STATUS = {
  READY: 'READY',
  ERROR: 'ERROR',
  WAITING: 'WAITING',
};

export const FILE_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  UPLOADING: 'UPLOADING',
  COMPLETE: 'COMPLETE',
  CANCELED: 'CANCELED',
  SUBMITTED: 'SUBMITTED',
  PROCESSERROR: 'PROCESSERROR',
};

export const NODE_TYPE = {
  LEAF: 'leaf',
  BRANCH: 'branch',
};

export const RECURRENCE_FREQUENCY = [
  { id: '', name: '' },
  { id: 'DAILY', name: 'Diária' },
  { id: 'WEEKLY', name: 'Semanal' },
  { id: 'MONTHLY', name: 'Mensal' },
];

export const STATUS = Object.keys(ActivityStatus).map(id => ActivityStatus[id]);

export const DEFAULT_ACTIVITY_STATUS = [
  ActivityStatus.LATE.id,
  ActivityStatus.WARNING.id,
  ActivityStatus.RUNNING.id,
  ActivityStatus.WAITING.id,
];

export const ALL_ACTIVITY_STATUS = [
  ...DEFAULT_ACTIVITY_STATUS,
  ActivityStatus.FINISHED.id,
];

export const VIEWS = {
  ACTIVITY_EDITOR: 'editing_activity',
  ACTIVITY_FORM: 'filling_out_activity',
  ADD_LAYER_INPUTS: 'add_layer_inputs',
  ADD_LAYER: 'add',
  APPEARANCE: 1,
  AREA_SNAPSHOTS: 'snapshots',
  AREA_SNAPSHOT_EDITING: 'snapshot_edit',
  LAYER_CREATING_SELECTOR: 'layer_creating_selector',
  BASIC_LAYER: 0,
  BLANK: 'blank',
  CREATE_SERVICE: 0,
  ELEMENTS: 'elements',
  ELEMENT: 'element',
  ELEMENT_DETAILS: 'element_details',
  FILES: 'files',
  FOLDERS: 'folders',
  GALLERY: 'gallery',
  GRID: 'grid',
  LAYERS: 'layers',
  LIST: 'list',
  LOGIN: 'login',
  MAP: 'map',
  MAP_ACTIVITIES: 'activities',
  MAP_CONFIGURATOR: 'configurator',
  MAP_LEGENDS: 'legends',
  MEASUREMENTS: 0,
  MOVING: 'moving',
  PROFILES: 1,
  PROFILE_PERMISSIONS: 2,
  RECOVERY: 'recovery',
  SERVICE_LIST: 1,
  THEMES: 'themes',
  TIMELINE: 'timeline',
  UPDATE_LAYER: 'update',
  UPLOAD_FILES: 'upload',
  UPLOAD_PROGRESS: 'progress',
  REDUCE_PROGRESS: 'reduceprogress',
  RESTRICT_USERS: 'restrict_users',
  USERS: 0,
  USER_PERMISSIONS: 0,
  UTILITIES: 1,
};

export const MESSAGES = {
  pt: {
    COULD_NOT_GET_CONFIG:
      'Não foi possível obter as configurações necessárias para abrir a aplicação.',
    ROUTE_NOT_FOUND: 'Não foi possível encontrar a página requisitada.',
    RELOAD: 'Atualizar a página',
    COULD_NOT_PROCESS_FILE: 'Não foi possível processar o arquivo.',
  },
  en: {
    COULD_NOT_GET_CONFIG: 'Could not get settings to open the application.',
    ROUTE_NOT_FOUND: 'Could not found the requested page.',
    RELOAD: 'Reload',
    COULD_NOT_PROCESS_FILE: 'Could not process the file.',
  },
};

export const CAROUSEL_STYLE = {
  dots: false,
  infinite: false,
  speed: 300,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 2,
};

export const MARKER_ICON_DEFAULT_OPTIONS = {
  path:
    'M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z',
  fillColor: '#ff0000',
  fillOpacity: 1,
  scale: 1,
  strokeColor: '#ff0000',
  strokeWeight: 0,
};

export const DEFAULT_MAP_STYLES = [
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];
