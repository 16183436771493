import axios from 'axios';

export default {
  filterElements: ({ themeId, filters }) =>
    axios.post(`/themes/${themeId}/elements/filter`, filters),

  getElementDetails: element =>
    axios.get(`/themes/${element.themeId}/elements/${element.id}`),

  exportElement: payload => {
    return axios({
      method: 'get',
      url: `/themes/${payload.theme.id}/elements/${payload.element.id}/export/${payload.templateId}`,
      responseType: 'blob',
    });
  },

  saveElement: element =>
    axios.post(`/themes/${element.themeId}/elements`, element),

  updateElement: element =>
    axios.put(`/themes/${element.themeId}/elements/${element.id}`, element),

  deleteElement: element =>
    axios.delete(`/themes/${element.themeId}/elements/${element.id}`),

  updateElementStatus: ({
    themeId,
    elementId,
    statusId,
    elementStatusHistory,
  }) =>
    axios.patch(
      `/themes/${themeId}/elements/${elementId}/status/${statusId}`,
      elementStatusHistory
    ),
};
