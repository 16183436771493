import { takeEvery, put, putResolve } from 'redux-saga/effects';

import api, { baseURL } from 'commons/api';
import notification from 'commons/notification';
import { mapFileService, fileService } from 'commons/services';
import { mapFileActions } from 'commons/store/mapFile';
import { appActions } from 'commons/store/app';
import { mapThemeActions } from 'commons/store/mapTheme';

function* initTree() {
  yield putResolve(
    mapFileActions.updateTreeNode([{ id: 'root', root: true, children: [] }])
  );
}

function* addNode({ payload }) {
  yield* api(
    mapFileService.addNode,
    payload,
    mapFileActions.updateTreeNode,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_NODE', { type: 'error' });
    },
    { multiple: false }
  );
}

function* updateNode({ payload }) {
  yield* api(
    mapFileService.updateNode,
    payload,
    mapFileActions.updateTreeNode,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_UPDATE_NODE', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* deleteNode({ payload }) {
  yield* api(
    mapFileService.deleteNode,
    payload,
    mapFileActions.updateTreeNode,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_DELETE_NODE', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* addFiles({ payload }) {
  yield* api(
    mapFileService.addFiles,
    payload,
    function*(data) {
      yield put(appActions.hideGlobalDrawer());
      yield put(mapFileActions.updateTreeNode(data));
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_FILES', { type: 'error' });
    }
  );
}

function* toggleTreeNode({ payload: node }) {
  if (!node.contracted) {
    yield* getTreeNode({ payload: { node: node.id } });
  }
  yield put(mapFileActions.updateNodeContractedState(node.id));
}

function* getTreeNode({ payload }) {
  const { node, silent } = payload;

  yield* api(
    mapFileService.getTreeNode,
    node,
    mapFileActions.updateTreeNode,
    function*(err) {
      yield notification('MESSAGES.FILES.COULD_NOT_GET_FILES_TREE', {
        type: 'error',
      });
    },
    { delay: 0, multiple: false, loading: !silent }
  );
}

function* getFileDetails({ payload }) {
  yield* api(
    fileService.getFileDetails,
    payload,
    function*(response) {
      yield put(mapFileActions.updateFileDetails(response));
      yield put(mapThemeActions.openVideoPlayer(true));
      if (response.goproGpsMetadata && response.goproGpsMetadata.length) {
        yield put(
          mapThemeActions.updateEditingElement({
            location: {
              lat: response.goproGpsMetadata[0].value[0],
              lng: response.goproGpsMetadata[0].value[1],
            },
          })
        );
        yield put(mapThemeActions.setIsGeoReferencedVideo(true));
      } else {
        yield put(mapThemeActions.setIsGeoReferencedVideo(false));
      }
    },
    err => console.log(err),
    { loading: false }
  );
}

function* reduceVideo({ payload }) {
  yield* api(
    fileService.reduceVideo,
    payload,
    function*(response) {
      yield notification(response, {
        type: 'success',
      });
    },
    err => console.log(err),
    { multiple: false, loading: false }
  );
}

function* downloadFile({ payload }) {
  yield window.open(`${baseURL}/files/download/${payload}`, '_blank');
}

function* insertNodeMapFile({ payload }) {
  const { parentId, node, silent } = payload;

  yield* api(
    fileService.insertNode,
    { parentId, node },
    function*(node) {
      yield* getTreeNode({ payload: { node: parentId, silent } });
    },
    function*(err) {
      yield notification('MESSAGES.FILES.COULD_NOT_CREATE_NEW_FOLDER', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

export default function*() {
  yield takeEvery(mapFileActions.addFiles.toString(), addFiles);
  yield takeEvery(mapFileActions.addNode.toString(), addNode);
  yield takeEvery(mapFileActions.updateNode.toString(), updateNode);
  yield takeEvery(mapFileActions.deleteNode.toString(), deleteNode);
  yield takeEvery(mapFileActions.initTree.toString(), initTree);
  yield takeEvery(mapFileActions.toggleTreeNode.toString(), toggleTreeNode);
  yield takeEvery(mapFileActions.getTreeNode.toString(), getTreeNode);
  yield takeEvery(mapFileActions.getFileDetails.toString(), getFileDetails);
  yield takeEvery(mapFileActions.reduceVideo.toString(), reduceVideo);
  yield takeEvery(mapFileActions.downloadFile.toString(), downloadFile);
  yield takeEvery(
    mapFileActions.insertNodeMapFile.toString(),
    insertNodeMapFile
  );
}
