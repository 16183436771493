import { mapThemeService } from 'commons/services';
import { mapThemeActions } from 'commons/store/mapTheme';
import { loadingActions } from 'commons/store/loading';

export const filterElements = payload => async dispatch => {
  const { themeId } = payload;

  await dispatch(
    loadingActions.setLoading({
      data: payload,
      action: filterElements.toString(),
    })
  );

  try {
    await dispatch(
      mapThemeActions.updateIntoThemes({
        themeId,
        key: 'elements',
        entity: [],
      })
    );
    const { data } = await mapThemeService.filterElements(payload);
    await dispatch(
      mapThemeActions.updateIntoThemes({
        themeId,
        key: 'elements',
        entity: data,
      })
    );
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(loadingActions.unsetLoading(filterElements.toString()));
  }
};

filterElements.toString = () => 'mapTheme/filterElements';
